<template>
    <div class="view pa24">
        <div class="selectCompany flex-a-c hover_pointer" @click="showCompanyList"><i
                class="el-icon-office-building fs12 mr5"></i> <span class="fs13">{{ checkedCompany.companyName }}</span>
            <i class="el-icon-caret-bottom  fs12 ml5"></i>
        </div>
        <div>
            <el-tabs v-model="year" @tab-click="searchFun">
                <el-tab-pane label="全部" name="all"></el-tab-pane>
                <el-tab-pane :label="String(y) + '年'" :name="String(y)" v-for="(y, index) in yearData"
                    :key="index"></el-tab-pane>
            </el-tabs>
        </div>
        <div class="d-flex mt20">
            <div>
                <el-input class="w300 mr10" placeholder="请输入姓名关键字" v-model="searchData.name" />
                <el-button type="primary" @click="searchFun">搜索</el-button>
            </div>
            <div style="margin-left: auto">
                <el-button type="primary" @click="exportXlsx(2)" class="wordBtn" :loading="exportLoadingBtn2"
                    :disabled="!checkedCompany.pcId || year == 'all'" title="请选择年份导出人员"><i
                        class="el-icon-document-remove"></i>
                    导出研发人员</el-button>
                <el-button type="primary" @click="exportXlsx('all')" class="wordBtn" :loading="exportLoadingBtn"
                    :disabled="!checkedCompany.pcId"><i class="el-icon-document"></i> 导出人员</el-button>
                <el-button type="primary" @click="uploadFilesVisible = true"
                    :disabled="!checkedCompany.pcId">导入人员</el-button>
                <el-button type="primary" @click="showAddModel" :disabled="!checkedCompany.pcId">新增人员</el-button>
                <el-button type="danger" @click="delUserFun()" :loading="delBtnLoading"
                    :disabled="checkedDelUser.length <= 0"><i class="el-icon-delete"></i> 删除{{ checkedDelUser.length
                    }}人员</el-button>
            </div>
        </div>
        <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
            @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total"
            @handleSelectionChange="handleSelectionChange">
            <template v-slot:table>
                <el-table-column type="selection" width="55" />
                <el-table-column type="index" align="center" label="序号" />
                <el-table-column prop="userName" align="center" label="姓名" show-overflow-tooltip width="100" />
                <el-table-column prop="sex" align="center" label="性别" show-overflow-tooltip width="100" />
                <el-table-column prop="department" align="center" label="部门" show-overflow-tooltip width="100" />
                <el-table-column prop="work" align="center" label="岗位" show-overflow-tooltip width="120" />
                <el-table-column prop="education" align="center" label="学历" show-overflow-tooltip width="80" />
                <el-table-column prop="university" align="center" label="毕业学校" show-overflow-tooltip min-width="200">
                    <template slot-scope="scope">
                        {{ scope.row.university ? scope.row.university : '-' }}
                    </template>
                </el-table-column>
                <el-table-column prop="major" align="center" label="专业" show-overflow-tooltip width="150">

                    <template slot-scope="scope">
                        {{ scope.row.major ? scope.row.major : '-' }}
                    </template>
                </el-table-column>
                <el-table-column prop="job" align="center" label="职称" show-overflow-tooltip>

                    <template slot-scope="scope">
                        {{ scope.row.job ? scope.row.job : '-' }}
                    </template>
                </el-table-column>
                <el-table-column prop="inTime" align="center" label="入职时间" show-overflow-tooltip width="120">

                    <template slot-scope="scope">
                        {{ scope.row.inTime ? scope.row.inTime.slice(0, 10) : "-" }}
                    </template>
                </el-table-column>
                <el-table-column prop="outTime" align="center" label="离职时间" show-overflow-tooltip width="120">

                    <template slot-scope="scope">
                        {{ scope.row.outTime ? scope.row.outTime.slice(0, 10) : "-" }}
                    </template>
                </el-table-column>
                <el-table-column prop="remark" align="center" label="备注" show-overflow-tooltip width="100">

                    <template slot-scope="scope">
                        {{ scope.row.remark ? scope.row.remark : '-' }}
                    </template>
                </el-table-column>
                <el-table-column align="center" label="操作" width="120">

                    <template slot-scope="scope">
                        <el-button type="text" @click="showUpdateModel(scope.row)">编辑</el-button>
                        <el-button style="color:#F56C6C" type="text" @click="delUser(scope.row)">删除
                        </el-button>
                    </template>
                </el-table-column>
            </template>
        </commonTable>
        <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="modelTitle"
            :visible.sync="showModel" :destroy-on-close="true" width="800px" center>
            <el-form :model="formData" :rules="rules" ref="formData" label-width="90px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="姓名" prop="userName">
                            <el-input v-model="formData.userName" placeholder="请输入姓名"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <!-- <el-form-item label="部门" prop="department">
                            <el-input v-model="formData.department" placeholder="请输入部门"></el-input>
                        </el-form-item> -->
                        <el-form-item label="部门" prop="departmentId">
                            <el-select class="w100p" v-model="formData.departmentId" clearable placeholder="请选择所属部门">
                                <el-option v-for="item in departmentData" :key="item.departmentId"
                                    :label="item.departmentName" :value="item.departmentId">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="性别" prop="sex">
                            <el-select class="w100p" v-model="formData.sex" clearable placeholder="性别">
                                <el-option label="男" value="男">
                                </el-option>
                                <el-option label="女" value="女">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="出生日期" prop="birth">
                            <el-date-picker style="width:100%" v-model="formData.birth" type="date"
                                placeholder="选择出生日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="岗位" prop="work">
                            <!-- <el-input placeholder="请输入岗位" v-model="formData.work" /> -->
                            <el-autocomplete class="inline-input w100p" v-model="formData.work"
                                :fetch-suggestions="workSearch" placeholder="请输入岗位"></el-autocomplete>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="职称" prop="job">
                            <el-input v-model="formData.job" placeholder="请输入职称"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="毕业学校" prop="university">
                    <el-input placeholder="请输入毕业学校" v-model="formData.university" />
                </el-form-item>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="学历" prop="education">
                            <!-- <el-input v-model="formData.education" placeholder="请输入学历"></el-input> -->
                            <!-- <el-select class="w100p" v-model="formData.education" clearable placeholder="请选择学历">
                                <el-option v-for="(item, index) in educationData" :key="index" :label="item.title"
                                    :value="item.id">
                                </el-option>
                            </el-select> -->
                            <el-autocomplete class="inline-input w100p" v-model="formData.education"
                                :fetch-suggestions="educationSearch" placeholder="请输入学历"></el-autocomplete>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="专业" prop="major">
                            <el-input placeholder="请输入专业" v-model="formData.major" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="入职时间" prop="inTime">
                            <el-date-picker style="width:100%" v-model="formData.inTime" type="date"
                                placeholder="选择入职日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="离职时间" prop="outTime">
                            <el-date-picker style="width:100%" v-model="formData.outTime" type="date"
                                placeholder="选择离职日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="备注" prop="remark">
                    <el-input placeholder="请输入备注" :rows="5" v-model="formData.remark" type="textarea" />
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="showModel = false">取 消</el-button>
                <el-button type="primary" @click="addUpdate" :loading="btnLoading">提 交</el-button>
            </span>
        </el-dialog>
        <importModel :data="{ pcId: this.checkedCompany.pcId }" :uploadFilesTitle="uploadFilesTitle"
            :uploadFilesVisible="uploadFilesVisible" :uploadModelUrl="uploadModelUrl"
            :downloadModelFileName="downloadModelFileName" :downloadModelUrl="downloadModelUrl"
            @UploadFilesSuccess="filesSuccess" @uploadFilesDialogClose="hideModel" />
        <select-company-list ref="selectCompanyList" @confirmFun="selectCompany"></select-company-list>
    </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import importModel from "@/components/common/importModel";
import selectCompanyList from "@/pages/projectAdmin/components/selectCompanyList";
import { getProjectUserList, setProjectUser, getProjectDepartment } from "@/api/projectAdmin";
export default {
    name: "groupingTable",
    components: {
        commonTable,
        importModel,
        selectCompanyList,
    },
    data() {
        return {
            uploadFilesTitle: "批量导入数据",
            downloadModelFileName: "项目人员模板",
            uploadModelUrl: "project/setProjectUserAll",
            downloadModelUrl: "project/projectUserExport",
            departmentData: [],
            //列表配置
            yearData: [],
            year: "all",
            currentPage: 1, //当前页
            pageSize: 10, //显示条数
            loading: false, //表格加载
            total: 0, //总条数
            showModel: false,
            tableData: [],
            searchData: { name: "" },
            userInfo: this.$store.state.loginRoot.userInfo,
            //模态框配置
            modelTitle: "",
            formData: {
                department: "",
                education: "",
                inTime: "",
                outTime: "",
                job: "",
                major: "",
                university: "",
                userName: "",
                work: "",
                remark: "",
                sex: "",
                birth: ""
            },
            rules: {
                userName: {
                    required: true,
                    trigger: "blur",
                    message: "请输入姓名",
                },
                work: {
                    required: true,
                    trigger: "blur",
                    message: "请输入岗位",
                },
                education: {
                    required: true,
                    trigger: "blur",
                    message: "请输入学历",
                },
                departmentId: {
                    required: true,
                    trigger: "blur",
                    message: "请输入部门",
                },
                // inTime: {
                //     required: true,
                //     trigger: "change",
                //     message: "请选择入职时间",
                // },
            },
            workData: this.$store.state.workData,
            educationData: this.$store.state.educationData,
            formType: "",
            updateId: 0,
            btnLoading: false,
            uploadFilesVisible: false,
            checkedCompany: {},
            exportLoadingBtn: false,
            exportLoadingBtn2: false,
            checkedDelUser: [],
            delBtnLoading: false,
        };
    },
    mounted() {
        let checkedCompany = JSON.parse(sessionStorage.getItem("checkedProjectCompany"));
        if (!checkedCompany) {
            this.showCompanyList();
        }
        this.checkedCompany = checkedCompany ? checkedCompany : { companyName: "请选择研发企业" };
        this.getYearData();
        //获取部门
        this.getProjectDepartment();
        if (this.$route.query.name) {
            this.searchData.name = this.$route.query.name;
        }
        this.getList();
    },
    methods: {
        /**@method 选择删除人员 */
        handleSelectionChange(data) {
            this.checkedDelUser = data;
        },
        /**@method 人员批量删除 */
        delUserFun() {
            this.$confirm("此操作将影响到研发项目，确定要继续删除这" + this.checkedDelUser.length + "条人员数据？", "删除人员", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let puIds = [];
                for (let row of this.checkedDelUser) {
                    puIds.push(row.puId);
                }
                let params = {
                    isDel: 1,
                    puIds: puIds.join(","),
                    pcId: this.checkedCompany.pcId
                }
                this.delBtnLoading = true;
                setProjectUser(params).then(res => {
                    this.delBtnLoading = false;
                    if (res.code === 200) {
                        this.$message.success(res.message);
                        this.searchData.name = "";
                        this.checkedDelUser = [];
                        this.getList();
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    this.delBtnLoading = false;
                    if (err.code === 201) {
                        this.$message.error(err.message);
                    }
                })
            })
        },
        educationSearch(queryString, cb) {
            var educationData = this.educationData;
            var results = queryString ? educationData.filter(this.createFilter(queryString)) : educationData;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        workSearch(queryString, cb) {
            var workData = this.workData;
            var results = queryString ? workData.filter(this.createFilter(queryString)) : workData;
            // 调用 callback 返回建议列表的数据
            cb(results);
        },
        createFilter(queryString) {
            return (restaurant) => {
                return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
            };
        },
        /**@method 获取部门 */
        getProjectDepartment() {
            this.departmentData = [];
            getProjectDepartment({ pcId: this.checkedCompany.pcId }).then(res => {
                if (res.data) {
                    this.departmentData = res.data;
                    if (res.data) {
                        this.formData.departmentId = res.data[0].departmentId;
                    }
                }
            })
        },
        showCompanyList() {
            this.$refs.selectCompanyList.showModelFun();
        },
        selectCompany(row) {
            this.checkedCompany = row;
            sessionStorage.setItem("checkedProjectCompany", JSON.stringify(row));
            this.searchData.name = "";
            this.searchFun();
            //获取部门
            this.getProjectDepartment();
        },
        delUser(row) {
            this.$confirm("此操作将影响到研发项目，确定要继续删除这条人员数据？", "删除人员", {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let params = {
                    isDel: 1,
                    puId: row.puId
                }
                setProjectUser(params).then(res => {
                    if (res.code === 200) {
                        this.$message.success('删除完成');
                        this.searchData.name = "";
                        this.getList();
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch(err => {
                    if (err.code === 201) {
                        this.$message.error(err.message);
                    }
                })
            })
        },
        filesSuccess() {
            this.currentPage = 1;
            this.getList();
            // this.uploadFilesVisible = false;
        },
        hideModel() {
            this.uploadFilesVisible = false;
        },
        /**@method 获取最近五年 */
        getYearData() {
            let yearData = [];
            let F = new Date().getFullYear();
            for (let i = 0; i <= 4; i++) {
                yearData.push(F - i);
            }
            this.year = String(yearData[1]);
            this.yearData = yearData;
        },
        /**@method 搜索 */
        searchFun() {
            this.currentPage = 1;
            this.getList();
        },
        /**@method 提交新增/修改数据 */
        addUpdate() {
            //修改添加会员等级
            this.$refs.formData.validate((valid) => {
                if (valid) {
                    this.btnLoading = true;
                    let params = {
                        ...this.formData,
                        pcId: this.checkedCompany.pcId
                    }
                    let message = "新增完成";
                    if (this.formType === 'update') {
                        params.puId = this.updateId;
                        message = "编辑完成"
                    }
                    setProjectUser(params).then(res => {
                        this.btnLoading = false;
                        if (res.code === 200) {
                            this.$message.success(message);
                            this.showModel = false;
                            this.getList();
                        } else {
                            this.$message.error(res.message);
                        }
                    }).catch(err => {
                        this.btnLoading = false;
                        if (err.code === 201) {
                            this.$message.error(err.message);
                        }
                    })
                }
            });
        },
        /**@method 获取列表 */
        async getList() {
            let params = {
                pageSize: this.pageSize,
                pageNum: this.currentPage,
                userName: this.searchData.name,
            };
           //console.log(params);
            if (this.year != 'all') {
                params.selectYear = this.year
            }
            if (!this.checkedCompany.pcId) {
                this.tableData = [];
                this.total = 0
                return;
            } else {
                params.pcId = this.checkedCompany.pcId;
            }
            try {
                this.loading = true;

                let result = await getProjectUserList(params)
                const { data } = result;
                this.loading = false;
                this.tableData = data.pageInfo.list;
                this.total = data.pageInfo.total;
            } catch (error) {
                this.loading = false;
                this.tableData = [];
                this.total = 0;
            }
        },
        /**@method 切换行 */
        handleSizeChange(val) {
            this.currentPage = 1;
            this.pageSize = val;
            this.getList();
        },
        /**@method 切换下一页 */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        exportXlsx(type) {
            let params = {
                pcId: this.checkedCompany.pcId,
                isOutPut: true
            };
            if (type == 'all') {
                this.exportLoadingBtn = true;
            } else {
                params.excelType = type;
                this.exportLoadingBtn2 = true;
            }
            if (this.year != 'all') {
                params.selectYear = this.year
            }
            getProjectUserList(params).then(res => {
                if (type == 'all') {
                    this.exportLoadingBtn = false;
                } else {
                    this.exportLoadingBtn2 = false;
                }
                if (res.data) {
                    this.$message.success("导出人员表...");
                    let link = document.createElement('a');
                    link.href = '/api/crmPc/project/downloadWordFile?fileName=' + res.data;
                    link.click();
                } else {
                    this.$message.error(res.message);
                }
            }).catch(err => {
                if (type == 'all') {
                    this.exportLoadingBtn = false;
                } else {
                    this.exportLoadingBtn2 = false;
                }
                if (err.code === 201) {
                    this.$message.error(err.message);
                }
            })
        },
        /**@method 显示新增模态框 */
        showAddModel() {
            this.showModel = true;
            this.modelTitle = "新增人员";
            this.formType = "add";
            this.formData = {
                departmentId: this.formData.departmentId,
                education: "",
                inTime: "",
                outTime: "",
                job: "",
                major: "",
                university: "",
                userName: "",
                work: "",
                remark: "",
                sex: "",
                birth: ""
            };
            this.updateId = 0;
        },
        /**@method 编辑
         * @param {Object} row - 当前点击行的值
         */
        showUpdateModel(row) {
            this.showModel = true;
            this.modelTitle = "编辑人员";
            this.formType = "update";
            this.updateId = row.puId;
            this.formData = {
                departmentId: row.departmentId,
                education: row.education,
                inTime: row.inTime,
                outTime: row.outTime,
                job: row.job,
                major: row.major,
                university: row.university,
                userName: row.userName,
                work: row.work,
                remark: row.remark,
                sex: row.sex,
                birth: row.birth
            };
        },
    },
};
</script>

<style lang="scss" scoped>
.selectCompany {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 700;
    color: #51CBCD;
    cursor: pointer;
}

.switchInput {
    display: flex;

    .selectType {
        width: 150px;
    }
}

.operation-left {
    margin-bottom: 20px;

    .el-button {
        width: 95px;
        height: 32px;
        background: #51cbcd;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;

        &:active {
            border-color: #51cbcd;
        }
    }
}
</style>